import { FaShapes, FaHandshake } from 'react-icons/fa';
import { MdInvertColors } from 'react-icons/md';
import { VscWorkspaceTrusted } from 'react-icons/vsc';
import { AiFillFire } from 'react-icons/ai';
import { TiTick } from 'react-icons/ti';

export const siteLinks = {
  home: {
    name: 'Home',
    url: '/',
    active: false,
  },
  gallery: {
    name: 'Gallery',
    url: '/gallery',
    active: false,
  },

  about: {
    name: 'About Us',
    url: '/about-us',
    active: false,
  },
  contact: {
    name: 'Contact Us',
    url: '/contact-us',
    active: false,
  },
  designs: {
    name: 'Desings',
    url: '/desings',
    active: false,
  },
};

export const kapbloxDetails = {
  location:
    'https://www.google.com/maps/place/Kapari+Works+Limited%2FKapblox.Co/@-1.1724471,36.9750188,17.63z/data=!4m5!3m4!1s0x182f40ecb0894a6f:0x4b302ac4b497fb8b!8m2!3d-1.1732267!4d36.9762191',
  email: 'kaparilimited@gmail.com',
  phonenumber: '+254 702 664 664',
  facebook: 'https://www.facebook.com/Kapblox',
  twitter: 'https://twitter.com/kapariworksltd',
};

export const whyUs = [
  {
    id: 1,
    icon: <FaShapes />,
    title: '20+ Designs and Shapes',
    description:
      'We have a more than 20 designs of concrete products for you to choose from. You are sure to find a design that compliments your space.',
  },
  {
    id: 2,
    icon: <MdInvertColors />,
    title: 'Numerous Colors',
    description:
      'Mix and match colors to create unique and eye catching patterns to enhance the look of the pavers and tiles even more.',
  },
  {
    id: 3,
    icon: <VscWorkspaceTrusted />,
    title: 'Experience & Reputation',
    description:
      'We have been in business since 2003 and have earned a reputation for quality service and beautiful work',
  },
];

export const advantages = [
  {
    id: 1,
    title: 'Durable & strong',
    description:
      'Paver blocks are very durable, and if they are adequately interlocked, they can easily last for about 20 years. Paver blocks can withstand hefty vehicular load as well.',
  },
  {
    id: 2,
    title: 'No special maintenance required',
    description:
      'Compared to concrete or asphalt surfaces. A simple water washing can help keep the blocks clean and bright.',
  },
  {
    id: 3,
    title: 'Slip-resistant and skid resistant',
    description:
      'This helps in reducing your chances of slipping or falling and injurying yourself.',
  },
  {
    id: 4,
    title: 'Weather Resistant',
    description:
      'They can be used in any weather conditions and are ready to use as a driveway or patio as soon as you set them, unlike concrete, which needs to cure for several days before you can use it.',
  },
];

export const testimonials = [
  {
    id: 1,
    testimonial:
      'Thanks for the quality tiles you delivered. We really appreciate.',
    name: 'Ricardo Wahome',
  },
  {
    id: 2,
    testimonial: 'Perfectly done. This is the quality sasa.',
    name: 'Dennis',
  },
  {
    id: 3,
    testimonial: 'I love your products, they are of good quality',
    name: 'Muus Msa',
  },
  {
    id: 4,
    testimonial: 'Wooow! so cute.',
    name: 'Mwangi wa Murage',
  },
];

export const values = [
  {
    id: 1,
    icon: <FaHandshake />,
    title: 'Integrity',
    description:
      'We stick to our commitments, treat everyone equitably, and communicate openly and honestly.',
  },
  {
    id: 2,
    icon: <AiFillFire />,
    title: 'Passion',
    description:
      'We love what we do and how we do it. This is the fuel that drives us day by day.',
  },
  {
    id: 3,
    icon: <TiTick />,
    title: 'Quality',
    description:
      'We create outdoor pavers and tiles that will endure the test of time. We strive to set the highest standard of quality in everything we do. ',
  },
];

import loader from '../assets/images/loading.gif';
import '../assets/styles/loading.scss';

const Loading = () => (
  <div className="loading__container">
    <img src={loader} alt="Loading spinner" />
  </div>
);

export default Loading;

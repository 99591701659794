import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { siteLinks } from './constants';
import Loading from './components/Loading';

const BaseRouter = () => {
  const HomePage = React.lazy(() => import('./pages/HomePage'));
  const GalleryPage = React.lazy(() => import('./pages/GalleryPage'));
  const AboutPage = React.lazy(() => import('./pages/AboutPage'));
  const ContactPage = React.lazy(() => import('./pages/ContactPage'));
  const DesignsPage = React.lazy(() => import('./pages/DesignsPage'));
  const TermsPage = React.lazy(() => import('./pages/TermsPage'));
  const PrivacyPage = React.lazy(() => import('./pages/PrivacyPage'));

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path={siteLinks.home.url} element={<HomePage />} />
        <Route path={siteLinks.gallery.url} element={<GalleryPage />} />
        <Route path={siteLinks.about.url} element={<AboutPage />} />
        <Route path={siteLinks.contact.url} element={<ContactPage />} />
        <Route path={siteLinks.designs.url} element={<DesignsPage />} />
        <Route path="/terms-and-conditions" element={<TermsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
      </Routes>
    </Suspense>
  );
};
export default BaseRouter;
